import {
  ActionButton,
  ActionsGroup,
  Alert,
  Col,
  Container,
  FAB,
  FabSpeedDial,
  Loading,
  Panel,
  ProtectedComponent,
  Row,
  SearchPagination,
  SearchPanel,
  Table,
  usePagedQuery
} from '@elotech/components';
import { ModuloDTO } from 'common/type';
import React, { useCallback, useEffect, useState } from 'react';

import { Roles } from '../../roles/RolesConsole';
import { ModuloService, PermissaoService } from '../../service';
import { Permissao } from '../../type';

type Props = {};

const buildFilter = (search: string, modulo: string) => {
  let filter = `(nome=="*${search}*",descricao=="*${search}*")`;
  if (modulo) {
    filter += `;modulo==${modulo}`;
  }
  return filter;
};

const PermissaoListPage: React.FC<Props> = () => {
  const [filter, setFilter] = useState('');
  const [modulos, setModulos] = useState<ModuloDTO[]>([]);
  const [moduloSelecionado, setModuloSelecionado] = useState('');

  const {
    doSearch,
    values,
    loading,
    pagination,
    doSortChange,
    doPagedSearch
  } = usePagedQuery({
    search: PermissaoService.findAll,
    onError: useCallback(error => {
      Alert.error(
        {
          title: 'Erro ao buscar as permissões.'
        },
        error
      );
    }, [])
  });

  useEffect(() => {
    ModuloService.getModulos().then(response => {
      setModulos(response.data);
    });
  }, []);

  useEffect(() => {
    doSearch(buildFilter(filter, moduloSelecionado));
  }, [moduloSelecionado]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFilter = () => {
    doSearch(buildFilter(filter, moduloSelecionado));
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <Panel isTable>
        <SearchPanel>
          <Row className="mb-xs">
            <Col md={2} className="form-group">
              <select
                value={moduloSelecionado}
                onChange={e => {
                  setModuloSelecionado(e.target.value);
                }}
              >
                <option value="">Todos</option>
                {modulos.map(modulo => (
                  <option key={modulo.codigo} value={modulo.codigo}>
                    {modulo.descricao}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={10} className="form-group">
              <input
                autoFocus
                value={filter}
                name="searchValue"
                placeholder="Digite o filtro"
                onChange={e => setFilter(e.target.value)}
                style={{ width: `calc(100% - 82px)`, height: '28px' }}
                onKeyDown={e => e.key === 'Enter' && onFilter()}
              />
              <button
                data-test-id="buttonAdicionarFiltro"
                className="btn filter"
                type="button"
                onClick={onFilter}
              >
                Filtrar
              </button>
            </Col>
          </Row>
        </SearchPanel>
        <Table
          values={values}
          loading={loading}
          sortOnHeaderColumnClick={doSortChange}
        >
          <Table.Column<Permissao>
            header="Módulo"
            value={permissao =>
              modulos.find(modulo => modulo.nome === permissao.modulo)
                ?.descricao
            }
            sortable
            name="modulo"
          />

          <Table.Column<Permissao>
            header="Nome"
            value={permissao => permissao.nome}
            sortable
            name="nome"
          />

          <Table.Column<Permissao>
            header="Descrição"
            value={permissao => permissao.descricao}
            sortable
            name="descricao"
          />
          <Table.Column<Permissao>
            header=""
            value={permissao => (
              <ActionsGroup>
                <ProtectedComponent role={Roles.permissao_manage.name}>
                  <ActionButton
                    key="edit-button"
                    icon="pencil-alt"
                    label="Editar"
                    path={`/permissoes/${permissao.id}`}
                  />
                </ProtectedComponent>
              </ActionsGroup>
            )}
          />
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
      <ProtectedComponent role={Roles.permissao_manage.name}>
        <FabSpeedDial icon="ellipsis-v" title="Ações">
          <FAB
            icon="plus"
            title="Nova Permissão"
            path="/permissoes/new"
            small
            ro
          />
        </FabSpeedDial>
      </ProtectedComponent>
    </Container>
  );
};

export default PermissaoListPage;
